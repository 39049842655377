import React from 'react';
import { Markup } from 'interweave';
import text from './html.js';

const TermsAndConditions = () => {
  return (
    <div style={{ width: '70%', marginLeft: '15%', paddingTop: '10rem' }}>
      <Markup content={text} />
    </div>
  );
};

export default TermsAndConditions;
